import * as React from "react";
import { ThemeProvider } from "styled-components";
import theme from "../styles/theme";
import styled from "styled-components";
import GlobalStyle from "../styles/GlobalStyle";
import Header from "../components/Header";
import Hero2 from "../components/Hero2";
import Footer from "../components/Footer";

const StyledMain = styled.main``;

const Layout2 = ({ children, category, date, title }) => {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Header />
      <Hero2 category={category} date={date} title={title} />
      <StyledMain>{children}</StyledMain>
      <Footer />
    </ThemeProvider>
  );
};

export default Layout2;
