import * as React from "react";
import styled from "styled-components";

const HeroBody = styled.div`
  background: #61dafb;
  color: white;

  @media (max-width: 768px) {
    font-size: 3rem;
    font-weight: 700;
  }
`;

const PostInfo = styled.div`
  display: flex;
  padding: 1rem;
  font-size: 1.2rem;
`;

const PostCategory = styled.div`
  padding: 0.2rem 1rem 0.2rem 1rem;
  background: #e6c886;
  border-radius: 20px;
`;

const PostDate = styled.div`
  padding: 0.2rem 1rem 0.2rem 1rem;
`;

const PostTitle = styled.h1`
  font-size: 3rem;
  font-weight: 700;
`;

const ContentWrapper2 = styled.div`
  max-width: 1300px;
  margin: 0 auto;
  padding: 1.5rem 10rem;
`;

const Hero2 = ({ category, date, title }) => {
  return (
    <HeroBody>
      <ContentWrapper2>
        <PostInfo>
          <PostCategory>{category}</PostCategory>
          <PostDate>{date}</PostDate>
        </PostInfo>
        <PostTitle>{title}</PostTitle>
      </ContentWrapper2>
    </HeroBody>
  );
};

export default Hero2;
