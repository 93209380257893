import * as React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import Layout2 from "../components/Layout2";
import ContentWrapper from "../components/ContentWrapper";
import SEO from "../components/SEO";

const Post = styled.article`
  padding: 4rem 18rem 4rem 18rem;
  color: white;

  h1 {
    margin-bottom: 3rem;
    border-bottom: 1px solid #999999;
    font-size: 2.5rem;
    font-weight: 600;
  }

  h2 {
    margin-top: 2rem;
    margin-bottom: 2rem;
    font-size: 1.7rem;
    font-weight: 600;
  }

  p {
    margin-left: 1rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  ul {
    padding-left: 3rem;
    margin-bottom: 3rem;
  }

  li {
    padding-left: 1rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    list-style: square;
  }

  a:hover {
    text-decoration: underline;
  }
`;

const PostPage = ({ data }) => {
  const post = data.markdownRemark;
  return (
    <Layout2
      category={post.frontmatter.category}
      date={post.frontmatter.date}
      title={post.frontmatter.title}
    >
      <SEO
        pageTitle={post.frontmatter.title}
        pageDescription={post.frontmatter.description}
      />
      <ContentWrapper>
        <Post>
          <div dangerouslySetInnerHTML={{ __html: post.html }} />
        </Post>
      </ContentWrapper>
    </Layout2>
  );
};

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        date
        category
      }
    }
  }
`;

export default PostPage;
